/**
 * icon:菜单项图标
 * roles:标明当前菜单项在何种角色下可以显示，如果不写此选项，表示该菜单项完全公开，在任何角色下都显示
 */
const menuList = [
  {
    title: "账户管理",
    path: "/consumer", key: "/consumer",
    icon: "user",
    children: [
      {
        title: "账号管理",
        path: "/consumer/account", key: "/consumer/account",
        btn:[
          { 
            title: '新增',key:'/consumer/account@xz',
          },
          { 
            title: '编辑',key:'/consumer/account@bj',
          },{
            title: '禁用/启用',key:'/consumer/account@jyqy',
          },
          {
            title: '删除',key:'/consumer/account@sc',
          },
        ],
      },
      {
        title: "角色管理",
        path: "/consumer/role", key: "/consumer/role",
        btn:[
          { 
            title: '新增',key:'/consumer/role@xz',
          },
          {
            title: '编辑',key:'/consumer/role@bj',
          },{
            title: '禁用/启用',key:'/consumer/role@jyqy',
          },
          {
            title: '删除',key:'/consumer/role@sc',
          },
        ],
      },
    ],
  },
  {
    title: "产品管理",
    path: "/product", key: "/product",
    icon: "fund",
    children: [
      {
        title: "产品管理",
        path: "/product/account", key: "/product/account",
        redirect: '/product/account/list',
        children:[
          {
            title: "产品列表",
            path: "/product/account/list", key: "/product/account/list",
            btn:[{
                title: '禁用/启用',key:'/product/account/list@jyqy',
              },
              {
                title: '删除',key:'/product/account/list@sc',
              },
            ],
          },
          {
            title: "新增产品",
            path: "/product/account/add", key: "/product/account/add",
            activeMenu: "/product/account/list",
            // conceal:true
          },
          {
            title: "编辑产品",
            path: "/product/account/edit", key: "/product/account/edit",
            activeMenu: "/product/account/list",
            // conceal:true
          },
          {
            title: "产品详情",
            path: "/product/account/detail", key: "/product/account/detail",
            activeMenu: "/product/account/list",
            // conceal:true
          },
        ]
      },
      {
        title: "计量单位",
        path: "/product/unit", key: "/product/unit",
        btn:[
          { 
            title: '新增',key:'/product/unit@xz',
          },
          { 
            title: '编辑',key:'/product/unit@bj',
          },
          {
            title: '删除',key:'/product/unit@sc',
          },
        ],
      },
      
    ],
  },
  {
    title: "系统管理",
    path: "/system", key: "/system",
    icon: "setting",
    children: [
      {
        title: "字典管理",
        path: "/system/dictionaries", key: "/system/dictionaries",
        btn:[
          {
            title: '新增',key:'/system/dictionaries@xz',
          },
          {
            title: '编辑',key:'/system/dictionaries@bj',
          },{
            title: '禁用/启用',key:'/system/dictionaries@jyqy',
          },
          {
            title: '删除',key:'/system/dictionaries@sc',
          },
          {
            title: '添加子级',key:'/system/dictionaries@tjzj',
          },
        ],
      },
      {
        title: "日志管理",
        path: "/system/log", key: "/system/log",
      },
    ],
  },
];
export default menuList;
