import React, { useState,useEffect } from "react";
import { Form, Icon, Input, Button, message, Spin,Checkbox   } from "antd";
import { connect } from "react-redux";
import "./index.less";
import { login, getUserInfo } from "@/store/actions";
const PasswordLogin = (props) => {
  const { form,  login, getUserInfo } = props;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({});
  const [disableLogin,setDisableLogin]=useState(true)

  const usernameNumber = form.getFieldValue('username');
  const passwordNumber = form.getFieldValue('password');
  useEffect(() => {
    if(JSON.parse(localStorage.getItem("account"))){
      setAccount(JSON.parse(localStorage.getItem("account")))
    }
    console.log();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(account.username&&account.password){
      setDisableLogin(false)
    }else{
      setDisableLogin(true)
    }
  }, [account])
  useEffect(() => {
    if(usernameNumber&&passwordNumber){
      setDisableLogin(false)
    }else{
      setDisableLogin(true)
    }
  }, [passwordNumber, usernameNumber]);

  const handleLogin = (username, password,type=1) => {
    // 登录完成后 发送请求 调用接口获取用户信息
    setLoading(true);
    login(username, password,type)
      .then((data) => {
        if(data.code===200){
          message.success("登录成功");
          handleUserInfo();
        }else{
          message.error(data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };
  // 获取用户信息
  const handleUserInfo = () => {
    getUserInfo()
      .then((data) => {})
      .catch((error) => {
        message.error(error);
      });
  };

  const handleSubmit = (event) => {
    // 阻止事件的默认行为
    event.preventDefault();

    // 对所有表单字段进行检验
    form.validateFields((err, values) => {
      
      // 检验成功
      if (!err) {
        if(values.remember){
          localStorage.setItem('account', JSON.stringify(values));
        }else{
          localStorage.removeItem('account');
        }
        const { username, password } = values;
        handleLogin(username, password);
      } else {
        console.log("检验失败!");
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className='formHeight'>
              <Spin spinning={loading} tip="登录中...">
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "请输入您的手机号码",
                      },
                      {
                        pattern: /^1[3-9]\d{9}$/,
                        message: "请输入正确的手机号",
                      },
                    ],
                    initialValue: account.username, // 初始值
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="请输入您的手机号码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "请输入密码",
                      },
                      {
                        pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
                        message: "请输入8-18位数字和字母组合的密码",
                      },
                    ],
                    initialValue: account.password, // 初始值
                  })(
                    <Input.Password
                    password
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="请输入8-18位数字和字母组合的密码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  
                  <Button disabled={disableLogin} type="primary" style={{marginTop:'10px'}} htmlType="submit" className="login-form-button">
                    登录
                  </Button>
                  <div className='remember'>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: account.remember,
                  })(<Checkbox>记住密码</Checkbox>)}
                  <div style={{width:'1px',backgroundColor:'#D9D9D9',height:'18px'}}></div>
                  <a className="login-form-forgot" href="#/changePassword">
                    忘记密码？
                  </a>
                  </div>
                </Form.Item>
              </Spin>
            </Form>
  );
};

const WrapLogin = Form.create()(PasswordLogin);

export default connect((state) => state.user, { login, getUserInfo })(
  WrapLogin
);
