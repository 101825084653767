import Loadable from 'react-loadable';
import Loading from '@/components/Loading'
const Dashboard = Loadable({loader: () => import(/*webpackChunkName:'Dashboard'*/'@/views/personalCenter'),loading: Loading});
// const Dashboard = Loadable({loader: () => import(/*webpackChunkName:'Dashboard'*/'@/views/dashboard'),loading: Loading});
const personalCenter = Loadable({loader: () => import(/*webpackChunkName:'Dashboard'*/'@/views/personalCenter'),loading: Loading});
const Error404 = Loadable({loader: () => import(/*webpackChunkName:'Error404'*/'@/views/error/404'),loading: Loading});
const User = Loadable({loader: () => import(/*webpackChunkName:'User'*/'@/views/user'),loading: Loading});
const About = Loadable({loader: () => import(/*webpackChunkName:'About'*/'@/views/about'),loading: Loading});
const Menu = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/resource/menu'),loading: Loading});
const Operation = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/resource/menu/operation'),loading: Loading});
const Role = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/resource/role'),loading: Loading});
const Lessee = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/operation/lessee'),loading: Loading});
const LesseeOperation = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/operation/lessee/operation'),loading: Loading});
const Account = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consumer/account'),loading: Loading});
const ConsumerRole = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consumer/role'),loading: Loading});
const Dictionaries = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/system/dictionaries'),loading: Loading});
const Log = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/system/log'),loading: Loading});

// 企业认证审核
const EnterpriseCertification = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/dealUser/enterpriseCertification'),loading: Loading});
const EnterpriseCertificationDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/dealUser/enterpriseCertification/detail'),loading: Loading});

// 用户列表
const UserList = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/dealUser/userList'),loading: Loading});
const UserListDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/dealUser/userList/detail'),loading: Loading});

//保证金管理
const EarnestMoney = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/dealUser/earnestMoney'),loading: Loading});

// 服务商管理
const ServiceProvider = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/serviceProvider'),loading: Loading});
const ServiceProviderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/serviceProvider/detail'),loading: Loading});

// 商品审核
const CommodityAudit = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/commodity/audit'),loading: Loading});
const CommodityAuditDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/commodity/audit/detail'),loading: Loading});

// 代采申请
const PurchasingApplyAudit = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingApply/audit'),loading: Loading});
const PurchasingApplySalesorder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingApply/salesOrder'),loading: Loading});
const PurchasingApplySalesorderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingApply/salesOrder/detail'),loading: Loading});
const PurchasingApplyPurchaseorder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingApply/purchaseOrder'),loading: Loading});

const PurchasingApplyPurchaseorderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingApply/purchaseOrder/detail'),loading: Loading});

//寄售管理
const ConsignmentSalesApplyFor = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consignmentSales/applyFor'),loading: Loading});
const ConsignmentSalesPurchaseOrder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consignmentSales/purchaseOrder'),loading: Loading});
const ConsignmentSalesPurchaseOrderAdd = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consignmentSales/purchaseOrder/add'),loading: Loading});
const ConsignmentSalesOrder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consignmentSales/order'),loading: Loading});
const ConsignmentSalesOrderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/consignmentSales/order/detail'),loading: Loading});
// 订单管理
const OrderForm = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/orderForm'),loading: Loading});
const OrderFormDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/orderForm/detail'),loading: Loading});

// 合同管理
const Contract = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/contract'),loading: Loading});
const ContractDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/contract/detail'),loading: Loading});


// 服务审核
const StorageAudit = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/storage/audit'),loading: Loading});
const StorageAuditDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/storage/audit/detail'),loading: Loading});
const StorageAuditCheck = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/storage/audit/check'),loading: Loading});

// 服务商详细
const StorageParticulars = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/storage/particulars'),loading: Loading});
const StorageParticularsDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/storage/particulars/detail'),loading: Loading});

//物流
const LogisticsOrder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/order'),loading: Loading});
const LogisticsOrderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/order/detail'),loading: Loading});
const LogisticsVehicle = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/vehicle'),loading: Loading});
const LogisticsVehicleDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/vehicle/detail'),loading: Loading});
const LogisticsWaybill = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/waybill'),loading: Loading});
const LogisticsWaybillrDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/waybill/detail'),loading: Loading});
const LogisticsReconciliation = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/reconciliation'),loading: Loading});
const LogisticsReconciliationDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/reconciliation/detail'),loading: Loading});
const LogisticsDealWith = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/dealWith'),loading: Loading});
const LogisticsDealWithDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/dealWith/detail'),loading: Loading});
const LogisticsContract = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/logistics/contract'),loading: Loading});


const QualityTestingsOrder = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/order'),loading: Loading});
const QualityTestingsOrderDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/order/detail'),loading: Loading});
const QualityTestingsApply = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/apply'),loading: Loading});
const QualityTestingsApplyDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/apply/detail'),loading: Loading});
const QualityTestingsQuality = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/quality'),loading: Loading});
const QualityTestingsQualityDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/qualityTesting/quality/detail'),loading: Loading});
// const PurchasingAgent = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/purchasingAgent'),loading: Loading});
const FinanceAccount = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/finance/account'),loading: Loading});
const FinanceReconciliation = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/finance/reconciliation'),loading: Loading});
const FinanceSettleAccounts = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/finance/settleAccounts'),loading: Loading});
const FinanceInvoice = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/finance/invoice'),loading: Loading});
const ConfigurationOpinion = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/opinion'),loading: Loading});
const ConfigurationOpinionDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/opinion/detail'),loading: Loading});
const ConfigurationBanner = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/banner'),loading: Loading});
const ConfigurationBannerDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/banner/detail'),loading: Loading});
const ConfigurationBannerUpdate = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/banner/update'),loading: Loading});
const ConfigurationAnnouncement = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/announcement'),loading: Loading});
const ConfigurationAnnouncementUpdate = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/announcement/update'),loading: Loading});
const ConfigurationProductDrawing = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/configuration/productDrawing'),loading: Loading});
const ProductAccount = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/product/account'),loading: Loading});
const ProductAccountDetail = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/product/account/detail'),loading: Loading});
const ProductAccountUpdate = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/product/account/update'),loading: Loading});
const ProductUnit = Loadable({loader: () => import(/*webpackChunkName:'Bug'*/'@/views/product/unit'),loading: Loading});

export default [
  { path: "/dashboard", component: Dashboard,  },
  { path: "/personalCenter", component: personalCenter,  },
  { path: "/user", component: User, },
  { path: "/about", component: About,  },
  { path: "/resource/menu", component: Menu, },
  { path: "/resource/operation", component: Operation, },
  { path: "/resource/role", component: Role, },
  { path: "/operation/lessee/list", component: Lessee, },
  { path: "/operation/lessee/add", component: LesseeOperation, },
  { path: "/operation/lessee/update", component: LesseeOperation, },
  { path: "/consumer/account", component: Account, },
  { path: "/consumer/role", component: ConsumerRole, },
  { path: "/system/dictionaries", component: Dictionaries, },
  { path: "/system/log", component: Log, },
  // 企业认证审核
  { path: "/dealUser/enterpriseCertification/list", component: EnterpriseCertification, },
  { path: "/dealUser/enterpriseCertification/detail", component: EnterpriseCertificationDetail, },
  // 用户列表
  { path: "/dealUser/userList/list", component: UserList, },
  { path: "/dealUser/userList/detail", component: UserListDetail, },
   // 保证金管理
   { path: "/dealUser/earnestMoney", component: EarnestMoney, },
  // 服务商管理
  { path: "/serviceProvider/list", component: ServiceProvider,  },
  { path: "/serviceProvider/detail", component: ServiceProviderDetail,  },
  // 商品审核
  { path: "/commodity/audit/list", component: CommodityAudit, },
  { path: "/commodity/audit/detail", component: CommodityAuditDetail, },

    // 代采申请
    { path: "/purchasingApply/audit/list", component: PurchasingApplyAudit, },
    { path: "/purchasingApply/salesOrder/list", component: PurchasingApplySalesorder },
    { path: "/purchasingApply/salesOrder/detail", component: PurchasingApplySalesorderDetail },
    { path: "/purchasingApply/purchaseOrder/list", component: PurchasingApplyPurchaseorder },
    { path: "/purchasingApply/purchaseOrder/detail", component: PurchasingApplyPurchaseorderDetail },
    
    //寄售管理
{ path: "/consignmentSales/applyFor/list", component: ConsignmentSalesApplyFor, },
{ path: "/consignmentSales/purchaseOrder/list", component: ConsignmentSalesPurchaseOrder },
{ path: "/consignmentSales/purchaseOrder/add", component: ConsignmentSalesPurchaseOrderAdd },
{ path: "/consignmentSales/order/list", component: ConsignmentSalesOrder },
{ path: "/consignmentSales/order/detail", component: ConsignmentSalesOrderDetail },
    // 订单管理
  { path: "/orderForm/list", component: OrderForm,  },
  { path: "/orderForm/detail", component: OrderFormDetail,  },

  // 合同管理
  { path: "/contract/list", component: Contract,  },
  { path: "/contract/detail", component: ContractDetail,  },

  // 服务审核
  { path: "/storage/audit/list", component: StorageAudit, },
  { path: "/storage/audit/detail", component: StorageAuditDetail, },
  { path: "/storage/audit/check", component: StorageAuditCheck, },

  // 服务商明细
  { path: "/storage/particulars/list", component: StorageParticulars, },
  { path: "/storage/particulars/detail", component: StorageParticularsDetail, },


  // { path: "/logistics/order", component: LogisticsOrder, },
  //物流
  { path: "/logistics/order/list", component: LogisticsOrder, },
  { path: "/logistics/order/detail", component: LogisticsOrderDetail, },
  { path: "/logistics/vehicle/list", component: LogisticsVehicle, },
  { path: "/logistics/vehicle/detail", component: LogisticsVehicleDetail, },
  { path: "/logistics/waybill/list", component: LogisticsWaybill, },
  { path: "/logistics/waybill/detail", component: LogisticsWaybillrDetail, },
  { path: "/logistics/reconciliation/list", component: LogisticsReconciliation, },
  { path: "/logistics/reconciliation/detail", component: LogisticsReconciliationDetail, },
  { path: "/logistics/dealWith/list", component: LogisticsDealWith, },
  { path: "/logistics/dealWith/detail", component: LogisticsDealWithDetail, },
  { path: "/logistics/contract", component: LogisticsContract, },

  // { path: "/qualityTesting/order", component: QualityTestingsOrder, },
  { path: "/qualityTesting/order/list", component: QualityTestingsOrder, },
  { path: "/qualityTesting/order/detail", component: QualityTestingsOrderDetail, },
  { path: "/qualityTesting/apply/list", component: QualityTestingsApply, },
  { path: "/qualityTesting/apply/detail", component: QualityTestingsApplyDetail, },
  { path: "/qualityTesting/quality/list", component: QualityTestingsQuality, },
  { path: "/qualityTesting/quality/detail", component: QualityTestingsQualityDetail, },
  // { path: "/purchasingAgent", component: PurchasingAgent,  },
  { path: "/finance/account", component: FinanceAccount, },
  { path: "/finance/reconciliation", component: FinanceReconciliation, },
  { path: "/finance/settleAccounts", component: FinanceSettleAccounts, },
  { path: "/finance/invoice", component: FinanceInvoice, },
  // { path: "/configuration/opinion", component: ConfigurationOpinion, },
  { path: "/configuration/opinion/list", component: ConfigurationOpinion, },
  { path: "/configuration/opinion/detail", component: ConfigurationOpinionDetail, },
  // { path: "/configuration/banner", component: ConfigurationBanner, },
  { path: "/configuration/banner/list", component: ConfigurationBanner, },
  { path: "/configuration/banner/detail", component: ConfigurationBannerDetail, },
  { path: "/configuration/banner/add", component: ConfigurationBannerUpdate, },
  { path: "/configuration/banner/edit", component: ConfigurationBannerUpdate, },
  // { path: "/configuration/announcement", component: ConfigurationAnnouncement, },
  { path: "/configuration/announcement/list", component: ConfigurationAnnouncement, },
  { path: "/configuration/announcement/add", component: ConfigurationAnnouncementUpdate, },
  { path: "/configuration/announcement/edit", component: ConfigurationAnnouncementUpdate, },
  { path: "/configuration/productDrawing", component: ConfigurationProductDrawing, },
  // { path: "/product/account", component: ProductAccount, },
  { path: "/product/account/list", component: ProductAccount, },
  { path: "/product/account/add", component: ProductAccountUpdate, },
  { path: "/product/account/edit", component: ProductAccountUpdate, },
  { path: "/product/account/detail", component: ProductAccountDetail, },
  { path: "/product/unit", component: ProductUnit, },
  { path: "/error/404", component: Error404 },
];
