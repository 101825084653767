import React,{useState} from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { Form, Input, Button, message,Icon } from 'antd';
import { reqFindPassword } from "@/api/login";
import { grtSendMsg } from "@/store/actions";
import "./index.less";
const ChangePassword = (props) => {
  const [codeState, setCodeState] = useState({
    count:60,
    show:false
  });
  const history = useHistory();
  const {form,grtSendMsg} = props;
  const { getFieldDecorator } = form;
  
  const getCheckCode = ()=>
  {
    const phoneNumber = form.getFieldValue('phone');
    if (!phoneNumber) {
      message.error('请输入手机号码！');
      return;
    }
    if(form.getFieldError('phone')){
      message.error('请填写正确的手机号');
      return;
    }
    setCodeState({
      count: 60,
			show:true
		})
    let { count} = codeState;
    const mobile = phoneNumber
    const type=2
    grtSendMsg(mobile,type).then((data) => {})
        .catch((error) => {
          message.error(error);
        });
		const time = setInterval(() => {
      if(count===1){
        clearInterval(time);
        setCodeState({
          count:60,
          show:false
        })
      }else{
        count--
        setCodeState({
          count: count,
          show: true,
      })
      }
    }, 1000);
	}

  // 验证新密码
  const validateNewPassword = (rule, value, callback) => {
    const confirmPassword = form.getFieldValue('confirm');
    if (value && confirmPassword && value !== confirmPassword) {
      callback('两次输入的密码不一致！');
    } else {
      callback();
    }
  };

  // 提交表单
  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: 调用修改密码的接口
    form.validateFields((err, values) => {
      // 检验成功
      if (!err) {
        const { phone, confirm,code } = values;
        reqFindPassword({code,mobile:phone,password:confirm})
        .then((response) => {
          const { data } = response;
          if (data.code === 200) {
            message.success('密码修改成功！');
            history.push('/login')
          } else {
            const msg = data.msg;
            message.error(msg);
          }
        })
        
      } else {
        message.error('检验失败');
      }
    });
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  return (
    <div className='forgot-password'>
        <div className='head'>
          中商运营中台
        </div>
      <div className="forgot-password-page">
      <h1>忘记密码</h1>
        <div className='page'>
        <Form {...formItemLayout}  onSubmit={handleSubmit} className="forgot-password-form">
          <Form.Item label="手机号">
            {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "请输入您的手机号码",
                        },
                        {
                          pattern: /^1[3-9]\d{9}$/,
                          message: "请输入正确的手机号",
                        },
                      ],
                      initialValue: '', // 初始值
                    })(
                      <Input
                        prefix={
                          <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="请输入您的手机号码"
                      />
                    )}
          </Form.Item>
          <Form.Item className='codeForm' label="验证码">
                        {getFieldDecorator("code", {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "请输入验证码",
                            },
                            {
                              pattern: /^\d{6}$/,
                              message: "仅可输入 6 位数字",
                            },
                            
                          ],
                          initialValue: '', // 初始值
                        })(
                          <Input
                          maxLength={6}
                            prefix={
                              <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                            }
                            placeholder="请输入验证码"
                          />
                        )}
                        <Button onClick={getCheckCode} disabled={codeState.show}>
                          <div style={{width:'70px'}}>
                          {codeState.show ? codeState.count : '获取验证码' }
                          </div>
                          </Button>
                      </Form.Item>
          <Form.Item label='输入新密码'>
          {getFieldDecorator("newPassword", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "请输入新密码！",
                        },
                        {
                          pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
                          message: "请输入8-18位数字和字母组合的密码",
                        },
                        { validator: validateNewPassword }
                      ],
                      initialValue: '', // 初始值
                    })(
                      <Input
                        prefix={
                          <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="password"
                        placeholder="请输入8-18位数字和字母组合的密码"
                      />
                    )}
          </Form.Item>
          <Form.Item label='确认新密码'>
            {getFieldDecorator("confirm", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "请确认新密码！",
                        },
                        {
                          pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
                          message: "请输入8-18位数字和字母组合的密码",
                        },
                        { validator: validateNewPassword }
                      ],
                      initialValue: '', // 初始值
                    })(
                      <Input
                        prefix={
                          <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="password"
                        placeholder="请确认新密码"
                      />
                    )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <div style={{textAlign:'center'}}>
              <Button type="primary" htmlType="submit" style={{padding:'0 20px'}} className="submit-btn">保存</Button>
              <Button onClick={()=>history.push('/login')}  style={{padding:'0 20px',marginLeft:'10px'}} className="submit-btn">取消</Button>
            </div>
          </Form.Item>
        </Form>
        </div>
      </div>
    </div>
  );
};
const WrapLogin = Form.create()(ChangePassword);
export default connect((state) => state.user,{ grtSendMsg })(
  WrapLogin
);;