import request from '@/utils/request'

export function reqUserInfo(data) {
  return request({
    url: '/web/user/sys/info',
    method: 'get',
    data
  })
}

export function reqUpdateInfo(data) {
  return request({
    url: '/web/user/sys/updateInfo',
    method: 'post',
    data
  })
}

export function reqBindingTel(data) {
  return request({
    url: '/web/user/sys/bindingTel',
    method: 'post',
    data
  })
}

export function reqResetPwd(data) {
  return request({
    url: '/web/user/sys/resetPwd',
    method: 'put',
    data
  })
}

export function getUsers() {
  return request({
    url: '/user/list',
    method: 'get'
  })
}

export function deleteUser(data) {
  return request({
    url: '/user/delete',
    method: 'post',
    data
  })
}

export function editUser(data) {
  return request({
    url: '/user/edit',
    method: 'post',
    data
  })
}

export function reqValidatUserID(data) {
  return request({
    url: '/user/validatUserID',
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/user/add',
    method: 'post',
    data
  })
}