/**
 * icon:菜单项图标
 * roles:标明当前菜单项在何种角色下可以显示，如果不写此选项，表示该菜单项完全公开，在任何角色下都显示
 */
const menuList = [
  {
    title: "用户管理",
    path: "/dealUser", key: "/dealUser",
    icon: "user",
    children: [
      {
        title: "企业认证审核",
        path: "/dealUser/enterpriseCertification", key: "/dealUser/enterpriseCertification",
        redirect: '/dealUser/enterpriseCertification/list',
        children:[
          {
            title: "企业认证审核列表",
            path: "/dealUser/enterpriseCertification/list",
             key: "/dealUser/enterpriseCertification/list",
             btn:[//btn
              {
                title: '审核',key:'/dealUser/enterpriseCertification@sh',
              },
              {
                title: '查看子账号',key:'/dealUser/enterpriseCertification@ckzzh',
              },
            ]
          },
          {
            title: "企业认证详情",
            path: "/dealUser/enterpriseCertification/detail", key: "/dealUser/enterpriseCertification/detail",
            activeMenu: "/dealUser/enterpriseCertification/list",
          }
        ]
      },
      {
        title: "用户列表",
        path: "/dealUser/userList", key: "/dealUser/userList",
        redirect: '/dealUser/userList/list',
        children:[
          {
            title: "列表数据",
            path: "/dealUser/userList/list", key: "/dealUser/userList/list",
            btn:[//btn
            {
              title: '启用/禁用',key:'/dealUser/userList@qyjy',
            },
          ]
          },
          {
            title: "用户详情",
            path: "/dealUser/userList/detail", key: "/dealUser/userList/detail",
            activeMenu: "/dealUser/userList/list",
          }
        ]
      },

    ],
  },
  {
    title: "保证金管理",
    icon: "book",
    path: "/dealUser/earnestMoney", key: "/dealUser/earnestMoney",
    btn:[//btn
        {
          title: '驳回',key:'/dealUser/earnestMoney@bh',
        },
        {
          title: '退款',key:'/dealUser/earnestMoney@tk',
        },
        {
          title: '确认收款',key:'/dealUser/earnestMoney@qrsk',
        },
      ]
  },
  {
    title: "服务商管理",
    path: "/serviceProvider", key: "/serviceProvider",
    icon: "home",
    redirect: '/serviceProvider/list',
    children:[
      {
        title: "服务商管理列表",
        path: "/serviceProvider/list", key: "/serviceProvider/list",
        btn:[//btn
            {
              title: '审核',key:'/serviceProvider/list@sh',
            },
            {
              title: '启用/禁用',key:'/serviceProvider/list@qyjy',
            },
            {
              title: '删除',key:'/serviceProvider/list@sc',
            },
          ]
      },
      {
        title: "服务商详情",
        path: "/serviceProvider/detail", key: "/serviceProvider/detail",
        activeMenu: "/serviceProvider/list",
      }
    ]
  },
  {
    title: "商品管理",
    path: "/commodity", key: "/commodity",
    icon: "audit",
    children: [
      {
        title: "商品审核",
        path: "/commodity/audit", key: "/commodity/audit",
        redirect: '/commodity/audit/list',
        children:[
          {
            title: "商品审核列表",
            path: "/commodity/audit/list", key: "/commodity/audit/list",
            btn:[//btn
            {
              title: '审核',key:'/commodity/audit/list@sh',
            }
          ]
          },
          {
            title: "商品审核详情",
            path: "/commodity/audit/detail", key: "/commodity/audit/detail",
            activeMenu: "/commodity/audit/list",
          }
        ]
      },
    ],
  },
  {
    title: "订单管理",
    path: "/orderForm", key: "/orderForm",
    icon: "profile",
    redirect: '/orderForm/list',
    children:[
      {
        title: "订单管理列表",
        path: "/orderForm/list", key: "/orderForm/list"
      },
      {
        title: "订单管理详情",
        path: "/orderForm/detail", key: "/orderForm/detail",
        activeMenu: "/orderForm/list",
      }
    ]
  },
  {
    title: "合同管理",
    path: "/contract", key: "/contract",
    icon: "file-ppt",
    redirect: '/contract/list',
    children:[
      {
        title: "合同管理列表",
        path: "/contract/list", key: "/contract/list"
      },
      {
        title: "合同管理详情",
        path: "/contract/detail", key: "/contract/detail",
        activeMenu: "/contract/list",
      }
    ]
  },
  {
    title: "物流管理",
    path: "/logistics", key: "/logistics",
    icon: "car",
    children: [
      {
        title: "订单管理",
        path: "/logistics/order", key: "/logistics/order",
        redirect: '/logistics/order/list',
        children:[
          {
            title: "订单列表",
            path: "/logistics/order/list", key: "/logistics/order/list",
            // conceal:true
          },
          {
            title: "物流订单详情",
            path: "/logistics/order/detail", key: "/logistics/order/detail",
            activeMenu: "/logistics/order/list",
            // conceal:true
          },
        ]
      },
      {
        title: "用车管理",
        path: "/logistics/vehicle", key: "/logistics/vehicle",
        redirect: '/logistics/vehicle/list',
        children:[
          {
            title: "用车列表",
            path: "/logistics/vehicle/list", key: "/logistics/vehicle/list",
            // conceal:true
          },
          {
            title: "用车详情",
            path: "/logistics/vehicle/detail", key: "/logistics/vehicle/detail",
            activeMenu: "/logistics/vehicle/list",
            // conceal:true
          },
        ]
      },
      {
        title: "运单管理",
        path: "/logistics/waybill", key: "/logistics/waybill",
        redirect: '/logistics/waybill/list',
        children:[
          {
            title: "运单列表",
            path: "/logistics/waybill/list", key: "/logistics/waybill/list",
            // conceal:true
          },
          {
            title: "运单详情",
            path: "/logistics/waybill/detail", key: "/logistics/waybill/detail",
            activeMenu: "/logistics/waybill/list",
            // conceal:true
          },
        ]
      },
      {
        title: "对账管理",
        path: "/logistics/reconciliation", key: "/logistics/reconciliation",
        redirect: '/logistics/reconciliation/list',
        children:[
          {
            title: "对账列表",
            path: "/logistics/reconciliation/list", key: "/logistics/reconciliation/list",
            // conceal:true
          },
          {
            title: "对账详情",
            path: "/logistics/reconciliation/detail", key: "/logistics/reconciliation/detail",
            activeMenu: "/logistics/reconciliation/list",
            // conceal:true
          },
        ]
      },
      {
        title: "应付管理",
        path: "/logistics/dealWith", key: "/logistics/dealWith",
        redirect: '/logistics/dealWith/list',
        children:[
          {
            title: "应付列表",
            path: "/logistics/dealWith/list", key: "/logistics/dealWith/list",
            // conceal:true
          },
          {
            title: "应付详情",
            path: "/logistics/dealWith/detail", key: "/logistics/dealWith/detail",
            activeMenu: "/logistics/dealWith/list",
            // conceal:true
          },
        ]
      },
      {
        title: "合同管理",
        path: "/logistics/contract", key: "/logistics/contract",
      },
    ],
  },
  {
    title: "寄售管理",
    path: "/consignmentSales", key: "/consignmentSales",
    icon: "audit",
    children: [
      {
        title: "寄售申请",
        path: "/consignmentSales/applyFor", key: "/consignmentSales/applyFor",
        redirect: '/consignmentSales/applyFor/list',
        children:[
          {
            title: "寄售申请列表",
            path: "/consignmentSales/applyFor/list", key: "/consignmentSales/applyFor/list",
            btn:[//btn
              {
                title: '去签约',key:'/consignmentSales/applyFor/list@qqy',
              },
            {
              title: '完成跟进',key:'/consignmentSales/applyFor/list@wcgj',
            },
            // {
            //   title: '发起签约',key:'/consignmentSales/applyFor/list@fqqy',
            // },
            // {
            //   title: '查看合同',key:'/consignmentSales/applyFor/list@ckht',
            // },
            // {
            //   title: '摘牌',key:'/consignmentSales/applyFor/list@zp',
            // },
            // {
            //   title: '取消',key:'/consignmentSales/applyFor/list@qx',
            // },
           
            // {
            //   title: '查看订单',key:'/consignmentSales/applyFor/list@ckdd',
            // },
          ]
          },
        ]
      },
      {
        title: "寄售产品",
        path: "/consignmentSales/purchaseOrder", key: "/consignmentSales/purchaseOrder",
        redirect: '/consignmentSales/purchaseOrder/list',
        children:[
          {
            title: "寄售产品列表",
            path: "/consignmentSales/purchaseOrder/list", 
            key: "/consignmentSales/purchaseOrder/list",
            btn:[
              {
                title: '下架',key:'/consignmentSales/purchaseOrder/list@xj',
              },
            ]
          },
          {
            title: "新增寄售",
            path: "/consignmentSales/purchaseOrder/add", 
            key: "/consignmentSales/purchaseOrder/add",
            activeMenu: "/consignmentSales/purchaseOrder/list",
          }
        ]
      },
      {
        title: "寄售订单",
        path: "/consignmentSales/order", key: "/consignmentSales/order",
        redirect: '/consignmentSales/order/list',
        children:[
          {
            title: "寄售订单列表",
            path: "/consignmentSales/order/list", key: "/consignmentSales/order/list",
            btn:[
              {
                title: '去签约',key:'/consignmentSales/order/list@qqy',
              },
            ]
          },
          {
            title: "寄售订单详情",
            path: "/consignmentSales/order/detail", 
            key: "/consignmentSales/order/detail",
            activeMenu: "/consignmentSales/order/list",
            btn:[
              {
                title: '创建发货计划',key:'/consignmentSales/order/list@cjfhjh',
              },
            ]
          }
        ]
      },
    ],
  },
  {
    title: "质检管理",
    path: "/qualityTesting", key: "/qualityTesting",
    icon: "file-done",
    children: [
      // {
      //   title: "质检订单",
      //   path: "/qualityTesting/order", key: "/qualityTesting/order",
      //   redirect: '/qualityTesting/order/list',
      //   children:[
      //     {
      //       title: "质检订单列表",
      //       path: "/qualityTesting/order/list", key: "/qualityTesting/order/list",
      //       btn:[//btn
      //       {
      //         title: '回传结果',key:'/qualityTesting/order/list@hcjg',
      //       }
      //     ]
      //     },
      //     {
      //       title: "质检详情",
      //       path: "/qualityTesting/order/detail", key: "/qualityTesting/order/detail",
      //       activeMenu: "/qualityTesting/order/list",
      //       // conceal:true
      //     },
      //   ]
      // },
      {
        title: "质检申请",
        path: "/qualityTesting/apply", key: "/qualityTesting/apply",
        redirect: '/qualityTesting/apply/list',
        children:[
          {
            title: "质检申请列表",
            path: "/qualityTesting/apply/list", key: "/qualityTesting/apply/list",
          },
          {
            title: "质检申请详情",
            path: "/qualityTesting/apply/detail", key: "/qualityTesting/apply/detail",
            activeMenu: "/qualityTesting/apply/list",
            // conceal:true
          },
        ]
      },
      {
        title: "质检单",
        path: "/qualityTesting/quality", key: "/qualityTesting/quality",
        redirect: '/qualityTesting/quality/list',
        children:[
          {
            title: "质检单列表",
            path: "/qualityTesting/quality/list", key: "/qualityTesting/quality/list",
          },
          {
            title: "质检单详情",
            path: "/qualityTesting/quality/detail", key: "/qualityTesting/quality/detail",
            activeMenu: "/qualityTesting/quality/list",
            // conceal:true
          },
        ]
      },
    ],
  },
  // {
  //   title: "代采管理",
  //   path: "/purchasingAgent", key: "/purchasingAgent",
  //   btn:[//btn
  //           {
  //             title: '添加跟进人',key:'/purchasingAgent@tjgjr',
  //           },{
  //             title: '添加跟进记录',key:'/purchasingAgent@tjgjjl',
  //           },{
  //             title: '历史跟进记录',key:'/purchasingAgent@lsgjjl',
  //           },
  //         ],
  //   icon: "shopping",
  // },
  {
    title: "代采申请",
    path: "/purchasingApply", key: "/purchasingApply",
    icon: "audit",
    children: [
      {
        title: "申请管理",
        path: "/purchasingApply/audit", key: "/purchasingApply/audit",
        redirect: '/purchasingApply/audit/list',
        children:[
          {
            title: "申请管理列表",
            path: "/purchasingApply/audit/list", key: "/purchasingApply/audit/list",
            btn:[//btn
            {
              title: '驳回',key:'/purchasingApply/audit/list@bh',
            },
            {
              title: '发起签约',key:'/purchasingApply/audit/list@fqqy',
            },
            {
              title: '代采查看合同',key:'/purchasingApply/audit/list@ckht',
            },
            {
              title: '摘牌',key:'/purchasingApply/audit/list@zp',
            },
            {
              title: '取消',key:'/purchasingApply/audit/list@qx',
            },
            {
              title: '去签约',key:'/purchasingApply/audit/list@qqy',
            },
            {
              title: '摘牌查看合同',key:'/purchasingApply/audit/list@zpckht',
            },
          ]
          },
          // {
          //   title: "申请管理详情",
          //   path: "/purchasingApply/audit/detail", key: "/purchasingApply/audit/detail",
          //   activeMenu: "/purchasingApply/audit/list",
          // }
        ]
      },
      {
        title: "销售订单",
        path: "/purchasingApply/salesOrder", key: "/purchasingApply/salesOrder",
        redirect: '/purchasingApply/salesOrder/list',
        children:[
          {
            title: "销售订单列表",
            path: "/purchasingApply/salesOrder/list", key: "/purchasingApply/salesOrder/list",
            btn:[//btn
            {
              title: '查看物流信息',key:'/purchasingApply/salesOrder/list@ckwlxx',
            },
          ]
          },
          {
            title: "销售订单详情",
            path: "/purchasingApply/salesOrder/detail", 
            key: "/purchasingApply/salesOrder/detail",
            activeMenu: "/purchasingApply/salesOrder/list",
          }
        ]
      },
      {
        title: "采购订单",
        path: "/purchasingApply/purchaseOrder", key: "/purchasingApply/purchaseOrder",
        redirect: '/purchasingApply/purchaseOrder/list',
        children:[
          {
            title: "采购订单列表",
            path: "/purchasingApply/purchaseOrder/list", key: "/purchasingApply/purchaseOrder/list",
            btn:[//btn
            {
              title: '查看物流信息',key:'/purchasingApply/purchaseOrder/list@ckwlxx',
            },
          ]
          },
          {
            title: "采购订单详情",
            path: "/purchasingApply/purchaseOrder/detail", key: "/purchasingApply/purchaseOrder/detail",
            activeMenu: "/purchasingApply/purchaseOrder/list",
            btn:[//btn
              {
                title: '履约完成',key:'/purchasingApply/purchaseOrder/list@lywc',
              },
              {
                title: '付款申请',key:'/purchasingApply/purchaseOrder/list@fksq',
              },
            ]
          }
        ]
      },
    ],
  },
  {
    title: "财务管理",
    path: "/finance", key: "/finance",
    icon: "transaction",
    children: [
      {
        title: "账户管理",
        path: "/finance/account", key: "/finance/account",
        btn:[//btn
            {
              title: '批量操作',key:'/finance/account@plcz',
            },{
              title: '编辑',key:'/finance/account@bj',
            },
          ],
      },
      {
        title: "对账管理",
        path: "/finance/reconciliation", key: "/finance/reconciliation",
        btn:[//btn
            {
              title: '导出',key:'/finance/reconciliation@dc',
            },
          ],
      },
      {
        title: "结算管理",
        path: "/finance/settleAccounts", key: "/finance/settleAccounts",
        btn:[//btn
            {
              title: '确认付款',key:'/finance/settleAccounts@qrfk',
            },
            {
              title: '核销',key:'/finance/settleAccounts@hx',
            },
          ],
      },
      {
        title: "发票管理",
        path: "/finance/invoice", key: "/finance/invoice",
        btn:[//btn
            {
              title: '确认开票',key:'/finance/invoice@qrkp',
            },
            {
              title: '申请开票',key:'/finance/invoice@sqkp',
            },
            {
              title: '重新开票',key:'/finance/invoice@cxkp',
            },
          ],
      },
    ],
  },
  {
    title: "配置管理",
    path: "/configuration", key: "/configuration",
    icon: "setting",
    children: [
      {
        title: "意见反馈",
        path: "/configuration/opinion", key: "/configuration/opinion",
        redirect: '/configuration/opinion/list',
        children:[
          {
            title: "意见反馈列表",
            path: "/configuration/opinion/list", key: "/configuration/opinion/list",
            btn:[//btn
            {
              title: '回复',key:'/configuration/opinion/list@hf',
            },
          ],
          },
          {
            title: "意见反馈详情",
            path: "/configuration/opinion/detail", key: "/configuration/opinion/detail",
            activeMenu: "/configuration/opinion/list",
          },
        ]
      },
      {
        title: "banner配置",
        path: "/configuration/banner", key: "/configuration/banner",
        redirect: '/configuration/banner/list',
        children:[
          {
            title: "banner列表",
            path: "/configuration/banner/list", key: "/configuration/banner/list",
            btn:[//btn
            {
              title: '下架',key:'/configuration/banner/list@xj',
            },
            {
              title: '编辑',key:'/configuration/banner/list@bj',
            },
            {
              title: '删除',key:'/configuration/banner/list@sc',
            },
            {
              title: '新增',key:'/configuration/banner/list@xz',
            },
          ],
          },
          {
            title: "新增banner",
            path: "/configuration/banner/add", key: "/configuration/banner/add",
            activeMenu: "/configuration/banner/list",
            // conceal:true
          },
          {
            title: "编辑banner",
            path: "/configuration/banner/edit", key: "/configuration/banner/edit",
            activeMenu: "/configuration/banner/list",
            // conceal:true
          },
          {
            title: "banner详情",
            path: "/configuration/banner/detail", key: "/configuration/banner/detail",
            activeMenu: "/configuration/banner/list",
            // conceal:true
          },
        ]
      },
      {
        title: "公告配置",
        path: "/configuration/announcement", key: "/configuration/announcement",
        redirect: '/configuration/announcement/list',
        children:[
          {
            title: "公告列表",
            path: "/configuration/announcement/list", key: "/configuration/announcement/list",
            btn:[//btn
            {
              title: '下架',key:'/configuration/announcement/list@xj',
            },
            {
              title: '是否置顶',key:'/configuration/announcement/list@sfzd',
            },
            {
              title: '删除',key:'/configuration/announcement/list@sc',
            },
          ],
          },
          {
            title: "新增公告",
            path: "/configuration/announcement/add", key: "/configuration/announcement/add",
            activeMenu: "/configuration/announcement/list",
            // conceal:true
          },
          {
            title: "编辑公告",
            path: "/configuration/announcement/edit", key: "/configuration/announcement/edit",
            activeMenu: "/configuration/announcement/list",
            // conceal:true
          },
        ]
      },
      // {
      //   title: "产品图配置",
      //   path: "/configuration/productDrawing", key: "/configuration/productDrawing",
      // },
    ],
  },
];
export default menuList;
