import React, { useState,useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, message, Spin,Checkbox,Tabs   } from "antd";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import "./index.less";
import { login, getUserInfo } from "@/store/actions";
import CodeLogin from "./codeLogin";
import PasswordLogin from "./passwordLogin";
import LoginFoot from '../../components/LoginFoot';
const { TabPane } = Tabs;
const Login = (props) => {
  const { form, token, login, getUserInfo } = props;
  // const { getFieldDecorator } = form;

  // const [loading, setLoading] = useState(false);
  // const [account, setAccount] = useState({});
  // const [codeState, setCodeState] = useState({
  //   count:60,
  //   show:false
  // });
  // useEffect(() => {
  //   if(JSON.parse(localStorage.getItem("account"))){
  //     setAccount(JSON.parse(localStorage.getItem("account")))
  //   }
  // }, [])
  // useEffect(() => {
  //   console.log(account);
  // }, [account])
  // const handleLogin = (username, password) => {
  //   // 登录完成后 发送请求 调用接口获取用户信息
  //   setLoading(true);
  //   login(username, password)
  //     .then((data) => {
  //       message.success("登录成功");
  //       handleUserInfo(data.token);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       message.error(error);
  //     });
  // };
  // 获取用户信息
  // const handleUserInfo = (token) => {
  //   getUserInfo(token)
  //     .then((data) => {})
  //     .catch((error) => {
  //       message.error(error);
  //     });
  // };

  // const handleSubmit = (event) => {
  //   // 阻止事件的默认行为
  //   event.preventDefault();

  //   // 对所有表单字段进行检验
  //   form.validateFields((err, values) => {
      
  //     // 检验成功
  //     if (!err) {
  //       if(values.remember){
  //         localStorage.setItem('account', JSON.stringify(values));
  //       }else{
  //         localStorage.removeItem('account');
  //       }
  //       const { username, password } = values;
  //       handleLogin(username, password);
  //     } else {
  //       console.log("检验失败!");
  //     }
  //   });
  // };
  function callback(key) {
    console.log(key);
  }
  // const getCheckCode = ()=>{
  //   setCodeState({
  //     count: 60,
	// 		show:true
	// 	})
  //   let { count} = codeState;
	// 	const time = setInterval(() => {
  //     if(count===1){
  //       clearInterval(time);
  //       setCodeState({
  //         count:60,
  //         show:false
  //       })
  //     }else{
  //       count--
  //       setCodeState({
  //         count: count,
  //         show: true,
  //     })
  //     }
  //   }, 1000);
	// }
  if (token) {
    // 跳转个人中心
    return <Redirect to="/dashboard" />;
  }
  
  return (
    
    <DocumentTitle title={"用户登录"}>
      <div className="login-container">
        <div  className="content">
          <div className='loginTitle'>
          Please Sign in
          </div>
        <Tabs defaultActiveKey="1" onChange={callback}>
          
          <TabPane tab="验证码登录" key="1">
            <div className='formHeight'>
              <CodeLogin></CodeLogin>
              {/* <Form onSubmit={handleSubmit} className='formHeight'>
                <Spin spinning={loading} tip="登录中...">
                  <Form.Item>
                    {getFieldDecorator("user", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "请输入您的手机号码",
                        },
                      ],
                      initialValue: '',
                    })(
                      <Input
                        prefix={
                          <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="请输入您的手机号码"
                      />
                    )}
                  </Form.Item>
                    <Form.Item className='codeForm'>
                      {getFieldDecorator("code", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "请输入验证码",
                          },
                          {
                            pattern: /^\d{6}$/,
                            message: "请输入正确的格式",
                          },
                          
                        ],
                        initialValue: '', 
                      })(
                        <Input
                        maxLength={6}
                          prefix={
                            <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                          }
                          placeholder="请输入验证码"
                        />
                      )}
                      <Button onClick={getCheckCode} disabled={codeState.show}>
                        <div style={{width:'70px'}}>
                        {codeState.show ? codeState.count : '获取验证码' }
                        </div>
                        </Button>
                    </Form.Item>
                  <Form.Item>

                    <Button type="primary" htmlType="submit" className="login-form-button">
                      登录
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <span>账号 : admin 密码 : 随便填</span>
                    <br />
                    <span>账号 : editor 密码 : 随便填</span>
                    <br />
                    <span>账号 : guest 密码 : 随便填</span>
                  </Form.Item>
                </Spin>
              </Form> */}
            </div>
          </TabPane>
          <TabPane tab="密码登录" key="2">
            <PasswordLogin></PasswordLogin>
            {/* <Form onSubmit={handleSubmit} className='formHeight'>
              <Spin spinning={loading} tip="登录中...">
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "请输入您的手机号码",
                      },
                    ],
                    initialValue: account.username,
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="请输入您的手机号码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "请输入8-18位数字和字母组合的密码",
                      },
                    ],
                    initialValue: account.password,
                  })(
                    <Input
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="请输入8-18位数字和字母组合的密码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: account.remember,
                  })(<Checkbox>记住密码</Checkbox>)}
                  <a className="login-form-forgot" href="#/changePassword">
                    忘记密码
                  </a>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    登录
                  </Button>
                </Form.Item>
                <Form.Item>
                  <span>账号 : admin 密码 : 随便填</span>
                  <br />
                  <span>账号 : editor 密码 : 随便填</span>
                  <br />
                  <span>账号 : guest 密码 : 随便填</span>
                </Form.Item>
              </Spin>
            </Form> */}
          </TabPane>
        </Tabs>
        </div>
        <div className='login-text'>
          <p>Hi，欢迎进入</p>
          <p>中商供应链管理系统运营中台</p>
        </div>
        <LoginFoot/>
      </div>
    </DocumentTitle>
  );
};

const WrapLogin = Form.create()(Login);

export default connect((state) => state.user, { login, getUserInfo })(
  WrapLogin
);
