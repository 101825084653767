import React,{useEffect,useState} from "react";
import { Redirect, withRouter, Route, Switch } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Layout } from "antd";
import { getMenuItemInMenuListByProperty } from "@/utils";
import { addTag } from "@/store/actions";
import routeList from "@/config/routeMap";
import menuNav from "@/config/menuNav";
// import menuList from "@/config/menuConfig";
// import menuListTwo from "@/config/menuConfigTwo";
// import menuConfigThree from "@/config/menuConfigThree";
const { Content } = Layout;

const getPageTitle = (menuList, pathname) => {
  let title = "中商运营中台";
  let item = getMenuItemInMenuListByProperty(menuList, "path", pathname);
  if (item) {
    title = `${item.title} - 中商运营中台`;
  }
  return title;
};
// const switchList=(navActive)=>{

//   switch (navActive) {
//     case '1':
//       return menuList;
//       case '2':
//         return menuListTwo;
//         case '3':
//           return menuConfigThree;

//     default:
//       return menuListTwo;
//   }
// }
const LayoutContent = (props) => {
  const { role, location,keys,navActive  } = props;
  const { pathname } = location;
  let [userInfo,setUserInfo]=useState({})
    useEffect(()=>{
      let data=JSON.parse(localStorage.getItem("userInfo"))  || {}
      setUserInfo(data)
      // let key=userInfo.key || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(()=>{
      let menuItem = (getMenuItemInMenuListByProperty(menuNav, "path", location.pathname)&&location.search)?
      {
        search:location.search,
        ...getMenuItemInMenuListByProperty(menuNav, "path", location.pathname)
      }:getMenuItemInMenuListByProperty(menuNav, "path", location.pathname);
      if(location.pathname!=='/personalCenter'){
        props.addTag(menuItem);
        }
      // let key=userInfo.key || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
  const handleFilter = (route) => {
    if(userInfo.key){
      return userInfo.key.includes(route.path) || route.path==='/dashboard' || route.path==='/personalCenter'||  route.path==='/error/404'
    }
    // 过滤没有权限的页面
    return !route.roles || route.roles.includes(role);
    // console.log(arr,route.path,arr.includes(route.path));
    // return arr.includes(route.path) || route.path==='/error/404'
  };
 
  return (
    <DocumentTitle title={getPageTitle(menuNav, pathname)}>
      <Content style={{ height: "calc(100% - 100px)",padding:'20px', }}>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            timeout={500}
            classNames="fade"
            exit={false}
          >
            <Switch location={location}>
              <Redirect exact from="/" to="/dashboard" />
              {routeList.map((route) => {
                return (
                  handleFilter(route) && (
                    <Route
                      component={route.component}
                      key={route.path}
                      path={route.path}
                    />
                  )
                );
              })}
              <Redirect to="/error/404" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Content>
    </DocumentTitle>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.app,
    ...state.user,
    ...state.settings,
  };
};

export default connect(mapStateToProps,{addTag})(withRouter(LayoutContent));
