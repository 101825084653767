import React,{useEffect,useState} from 'react'
import { useHistory } from 'react-router-dom'
export default function Preview() {
  const [url,setUrl]=useState('')
  const [imgUrl,setImgUrl]=useState('')
  const history = useHistory()
  const query = new URLSearchParams(history.location.search)
  useEffect(()=>{

    prohibit()
    if(query.get('url')){
      lookFile(query.get('type'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const prohibit = ()=> {
    document.oncontextmenu = function () {
      return false
    }
    document.onkeydown = function (e) {
      if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e.keyCode === 117)) {return false}if (e.keyCode === 18 || e.keyCode === 123) {
        return false
      }
    }
  }
  const lookFile=(type)=> {
      let queryUrl = query.get('url') || ''
      let doc=/.doc|.docx/
      let pdf=/.pdf/
      if(doc.test(queryUrl)) {
        setUrl('https://view.officeapps.live.com/op/view.aspx?src=' + queryUrl)
      }else if(pdf.test(queryUrl)) {
        setUrl(queryUrl)
      }else{
        setImgUrl(queryUrl)
      }
  }
  return (
    <div>
      {
        url ?
        <iframe src={url} id="myIframe" width="100%" height="1000px"></iframe>
        :
        <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center',background:'#efefef'}}>
          <img style={{width:'90%',height:'90%',objectFit:'contain'}} src={imgUrl} alt="" />
        </div>
      }
    </div>
  )
}
