import { setUserToken, resetUser } from "./user";
import { reqLogin, reqLogout } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";
export const login = (username, password,type,code) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const data= type===1 ? { mobile: username.trim(), password: password,type }  : { mobile: username.trim(),type,code: password, }
    reqLogin(data)
      .then((response) => {
        const { data } = response;
        if (data.code === 200) {
          const token = data.data.token;
          dispatch(setUserToken(token));
          setToken(token);
          resolve(data);
          localStorage.setItem('navActive','1')
        } else {
          const msg = data.msg;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const logout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    reqLogout()
      .then((response) => {
        const { data } = response;
        if (data.code === 200) {
          dispatch(resetUser());
          removeToken();
          localStorage.removeItem("userInfo")
          localStorage.setItem('navActive','1')
          window.location.reload()
          resolve(data);
        } else {
          const msg = data.msg;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
