/**
 * icon:菜单项图标
 * roles:标明当前菜单项在何种角色下可以显示，如果不写此选项，表示该菜单项完全公开，在任何角色下都显示
 */
const menuList = [
  {
    title: "首页",
    path: "/dashboard", key: "/dashboard",
    icon: "home",
  },
  {
    title: "资源管理",
    path: "/resource", key: "/resource",
    icon: "appstore",
    children: [
      {
        title: "菜单管理",
        path: "/resource/menu", key: "/resource/menu",
        btn:[
          {
            title: '新增菜单',key:'resource/menu@xzcd',
          },{
            title: '编辑',key:'resource/menu@bj',
          },
          {
            title: '禁用/启用',key:'resource/menu@jyqy',
          },
          {
            title: '添加下级',key:'resource/menu@tjxj',
          },
          {
            title: '删除',key:'resource/menu@sc',
          },
        ]
      },
      {
        title: "角色管理",
        path: "/resource/role", key: "/resource/role",
        btn:[
          {
            title: '新增角色',key:'/resource/role@xzjs',
          },{
            title: '编辑',key:'/resource/role@bj',
          },
          {
            title: '禁用/启用',key:'/resource/role@jyqy',
          },
          {
            title: '删除',key:'/resource/role@sc',
          },
        ]
      },
    ],
  },
  {
    title: "运营管理",
    path: "/operation", key: "/operation",
    icon: "fund",
    children: [
      {
        title: "租户管理",
        path: "/operation/lessee", key: "/operation/lessee",
        redirect: '/operation/lessee/list',
        children: [
          {
            title: "租户列表",
            path: "/operation/lessee/list", key: "/operation/lessee/list",
            btn:[
              {
                title: '用户',key:'/operation/lessee@yh',
              },{
                title: '重置密码',key:'/operation/lessee@czmm',
              },
              {
                title: '删除',key:'/operation/lessee@sc',
              },
            ],
          },
          {
            title: "新增租户",
            path: "/operation/lessee/add", key: "/operation/lessee/add",
            activeMenu: "/operation/lessee/list",
            // conceal:true
          },
          {
            title: "编辑租户",
            path: "/operation/lessee/update", key: "/operation/lessee/update",
            activeMenu: "/operation/lessee/list",
            // conceal:true
          },
        ]
      },
    ],
  },
];
export default menuList;
