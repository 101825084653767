import React from "react";
import "./index.less";
const Mallki = () => {
  return (
    <div className="copyright">
      <div> Copyright © 2024 江苏中商碳素研究院有限公司 备案号：<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">苏ICP备16063958号-10</a></div>
      {/* <div className="beian">
        <img src="" alt="" style={{width:'24px',height:'24px'}}/>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">沪公网安备 31011802004438号-1</a>
        </div> */}
    </div>
  );
};


export default Mallki; 