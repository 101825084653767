import React from "react";
import { connect } from "react-redux";
import { Icon, Menu, Dropdown, Modal, Layout, Avatar } from "antd";
import { Link,useHistory ,withRouter} from "react-router-dom";
import store from "@/store";
import { logout, getUserInfo,navUptade } from "@/store/actions";
import { closeOtherTags } from "@/store/actions";
// import FullScreen from "@/components/FullScreen";
// import Settings from "@/components/Settings";
// import Hamburger from "@/components/Hamburger";
// import BreadCrumb from "@/components/BreadCrumb";
import "./index.less";
const { Header } = Layout;

const LayoutHeader = (props) => {

  const {
    token,
    avatar,
    location,
    sidebarCollapsed,
    logout,
    navActive,
    getUserInfo,
    showSettings,
    navUptade,
    fixedHeader,
  } = props;
  const userInfo=JSON.parse(localStorage.getItem("userInfo")) || {}
  // token && getUserInfo(token);
  const history = useHistory();
  const getOperation=()=>{
    // history.push('/inform')
  }
  const handleLogout = () => {
    Modal.confirm({
      title: "退出登录",
      content: "确定要退出系统吗?",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        logout();
      },
    });
  };
  const onClick = ({ key }) => {
    switch (key) {
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };
  const MenuChange=({key})=>{
    store.dispatch(navUptade(key));
    localStorage.setItem('navActive',key)
    
    switch (key) {
      case '1':
        props.closeOtherTags({
          title: "首页",
          path: "/dashboard", key: "/dashboard",
        },)
        history.push('/dashboard');
        break;
        case '2':
          props.closeOtherTags({
            title: "企业认证审核",
            path: "/dealUser/enterpriseCertification/list", key: "/dealUser/enterpriseCertification/list"
          })
          history.push('/dealUser/enterpriseCertification/list');
        break;
        case '3':
          props.closeOtherTags({
            title: "账号管理",
            path: "/consumer/account", key: "/consumer/account",
            btn:[
              { 
                title: '编辑',key:'/consumer/account@bj',
              },{
                title: '禁用/启用',key:'/consumer/account@jyqy',
              },
              {
                title: '删除',key:'/consumer/account@sc',
              },
            ],
          })
          history.push('/consumer/account');
        break;
      default:
        break;
    }
    // window.location.reload();



    // 强制刷新页面
    // window.location.reload();
    
  }
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="dashboard">
        <Link to="/dashboard">首页</Link>
      </Menu.Item>
      <Menu.Item key="dashboard">
        <Link to="/personalCenter">个人中心</Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="logout">退出登录</Menu.Item>
    </Menu>
  );
  const computedStyle = () => {
    let styles;
    if (fixedHeader) {
      if (sidebarCollapsed) {
        styles = {
          width: "calc(100% - 80px)",
        };
      } else {
        styles = {
          width: "calc(100% - 200px)",
        };
      }
    } else {
      styles = {
        width: "100%",
      };
    }
    return styles;
  };
  return (
    <>
      {/* 这里是仿照antd pro的做法,如果固定header，
      则header的定位变为fixed，此时需要一个定位为relative的header把原来的header位置撑起来 */}
      {fixedHeader ? <Header /> : null}
      <Header
        style={computedStyle()}
        className={fixedHeader ? "fix-header" : ""}
      >
        <Menu
        theme="dark"
        mode="horizontal"
        onSelect={MenuChange}
        selectedKeys={[navActive]}
        style={{ lineHeight: '64px',}}
      >

        <Menu.Item key="2">
        交易平台
        </Menu.Item>
        <Menu.Item key="1">
        供应链
          </Menu.Item>
        <Menu.Item key="3">
        基础配置
          </Menu.Item>
      </Menu>
        {/* <Hamburger /> */}
        {/* <BreadCrumb /> */}
        <div className="right-menu" style={{ marginTop: '-64px',}}>
        <span className='fullScreen-container' onClick={getOperation}>
        {/* <Icon type="bell" /> */}
        </span>
          {/* <FullScreen /> */}
          {/* {showSettings ? <Settings /> : null} */}
          <div className="dropdown-wrap">
            <Dropdown overlay={menu}>
              <div>
                <Avatar shape="square" size="medium" src={userInfo.headImg} />
                <span className='fullName'>
                {userInfo.fullName}
                </span>
                <Icon style={{ color: "#fff" }} type="caret-down" />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.app,
    ...state.user,
    ...state.settings,
  };
};
export default connect(mapStateToProps, { logout, getUserInfo,navUptade,closeOtherTags })(withRouter(LayoutHeader));
