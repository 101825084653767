import React, { useState,useEffect } from "react";
import { Form, Icon, Input, Button, message, Spin, } from "antd";
import { connect } from "react-redux";
import "./index.less";
import { login, getUserInfo,grtSendMsg } from "@/store/actions";
import { disableCache } from '@iconify/iconify';
const CodeLogin = (props) => {
  const { form,  login, getUserInfo,grtSendMsg } = props;
  const { getFieldDecorator } = form;

  const [loading, setLoading] = useState(false);
  const [disableLogin,setDisableLogin]=useState(true)
  const usernameNumber = form.getFieldValue('username');
  const codeNumber = form.getFieldValue('code');
  const [codeState, setCodeState] = useState({
    count:60,
    show:false
  });
  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(usernameNumber&&codeNumber){
      setDisableLogin(false)
    }else{
      setDisableLogin(true)
    }
  }, [usernameNumber, codeNumber]);

  const handleLogin = (username, code,type=2) => {
    // 登录完成后 发送请求 调用接口获取用户信息
    setLoading(true);
    login(username, code,type)
      .then((data) => {
        if(data.code===200){
          message.success("登录成功");
          handleUserInfo();
        }else{
          message.error(data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };
  // 获取用户信息
  const handleUserInfo = () => {
    getUserInfo()
      .then((data) => {})
      .catch((error) => {
        message.error(error);
      });
  };

  const handleSubmit = (event) => {
    // 阻止事件的默认行为
    event.preventDefault();

    // 对所有表单字段进行检验
    form.validateFields((err, values) => {
      
      // 检验成功
      if (!err) {
        const { username, code } = values;
        handleLogin(username, code);
      } else {
        console.log("检验失败!");
      }
    });
  };
  const getCheckCode = ()=>{
    
    form.validateFields(['username'],(err, values) => {
      // 检验成功
      if (!err) {
        if(!codeState.show){
          setCodeState({
            count: 60,
            show:true
          })
          let { count} = codeState;
          const { username:mobile, code,type=1 } = values;
        grtSendMsg(mobile,type).then((data) => {})
        .catch((error) => {
          message.error(error.msg);
        });
        const time = setInterval(() => {
          if(count===1){
            clearInterval(time);
            setCodeState({
              count:60,
              show:false
            })
          }else{
            count--
            setCodeState({
              count: count,
              show: true,
          })
          }
        }, 1000);
        }
        
      } else {
        console.log("检验失败!");
      }
    });
    
    
	}
  return (
    <Form onSubmit={handleSubmit} className='formHeight'>
                <Spin spinning={loading} tip="登录中...">
                  <Form.Item>
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "请输入您的手机号码",
                        },
                        {
                          pattern: /^1[3-9]\d{9}$/,
                          message: "请输入正确的手机号",
                        },
                      ],
                      initialValue: '', // 初始值
                    })(
                      <Input
                        prefix={
                          <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="请输入您的手机号码"
                      />
                    )}
                  </Form.Item>
                    <Form.Item className='codeForm'>
                      {getFieldDecorator("code", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "请输入验证码",
                          },
                          {
                            pattern: /^\d{6}$/,
                            message: "仅可输入 6 位数字",
                          },
                          
                        ],
                        initialValue: '', // 初始值
                      })(
                        <Input
                        maxLength={6}
                          prefix={
                            <Icon type="safety-certificate" style={{ color: "rgba(0,0,0,.25)" }} />
                          }
                          placeholder="请输入验证码"
                          addonAfter={
                          <div onClick={getCheckCode}>
                          <div style={{width:'70px',color: '#3078FF',cursor:'pointer'}}>
                          {codeState.show ? codeState.count+'s' : '获取验证码' }
                          </div>
                          </div>
                          }
                        />
                      )}
                      
                    </Form.Item>
                  <Form.Item>

                    <Button type="primary" disabled={disableLogin} style={{marginTop:'10px'}} htmlType="submit" className="login-form-button">
                      登录
                    </Button>
                  </Form.Item>
                </Spin>
              </Form>
  );
};

const WrapLogin = Form.create()(CodeLogin);

export default connect((state) => state.user, { login, getUserInfo,grtSendMsg })(
  WrapLogin
);
