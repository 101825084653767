import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import store from "@/store";
import { getUserInfo ,navUptade} from "@/store/actions";
import Layout from "@/views/layout";
import Login from "@/views/login";
import ChangePassword from "@/views/changePassword";
import Preview from "@/views/preview";
class Router extends React.Component {
  render() {
    const { token, role, getUserInfo ,navUptade} = this.props;
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/changePassword" component={ChangePassword} />
          <Route exact path="/preview" component={Preview} />
          <Route
            path="/"
            render={({location}) => {
              if(location.pathname==='/dashboard' || location.pathname==='/'){
                store.dispatch(navUptade('1'));
                localStorage.setItem('navActive','1')
              }
              if (!token) {
                return <Redirect to="/login" />;
              } else {
                return <Layout />;
                // if (role) {
                //   return <Layout />;
                // } else {
                //   getUserInfo(token).then(() => <Layout />);
                // }
              }
            }}
          />
        </Switch>
      </HashRouter>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.app,
    ...state.user,
    ...state.settings,
  };
};
export default connect(mapStateToProps, { getUserInfo,navUptade })(Router);
