import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { addTag } from "@/store/actions";
import { getMenuItemInMenuListByProperty } from "@/utils";
import menuList from "@/config/menuConfig";
import menuNav from "@/config/menuNav";

import menuListTwo from "@/config/menuConfigTwo";
import menuConfigThree from "@/config/menuConfigThree";

import "./index.less";
const SubMenu = Menu.SubMenu;
// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class Meun extends Component {
  state = {
    menuTreeNode: null,
    openKey: [],
    keys:[],
    userInfo:{},
  };

  // filterMenuItem用来根据配置信息筛选可以显示的菜单项
  filterMenuItem = (item) => {
    const { roles } = item;
    const userInfo=JSON.parse(localStorage.getItem("userInfo")) || {}
    const { role,keys=userInfo.key } = this.props;
    let arr=userInfo.key ? [...userInfo.key,'/dashboard','/personalCenter','/error/404'] : []
    if(item.conceal) return false;
    if ( !roles || roles.includes(role)) {
      if(keys){
        return arr.includes(item.path);
      }
      // return true;
    } else if (item.children) {
      // 如果当前用户有此item的某个子item的权限
      return !!item.children.find((child) => roles.includes(child.role));
    }
    return false;
  };
  // 菜单渲染
  getMenuNodes = (menuList) => {
    // 得到当前请求的路由路径
    const path = this.props.location.pathname;
    return menuList.reduce((pre, item) => {
      if (this.filterMenuItem(item)) {
        if (!item.children) {
          pre.push(
            <Menu.Item key={item.path}>
              <Link to={item.path}>
                {item.icon ? <Icon type={item.icon} /> : null}
                <span>{item.title}</span>
              </Link>
            </Menu.Item>
          );
        } else {
          if(item.redirect){
            pre.push(
              <Menu.Item key={item.redirect}>
                <Link to={item.redirect}>
                  {item.icon? <Icon type={item.icon} /> : null}
                  <span>{item.title}</span>
                </Link>
              </Menu.Item>
            )
          }else{
            // 查找一个与当前请求路径匹配的子Item
            const cItem = item.children.find(
              (cItem) => path.indexOf(cItem.path) === 0
            );
            // 如果存在, 说明当前item的子列表需要打开
            if (cItem) {
              this.setState((state) => ({
                openKey: [...state.openKey, item.path],
              }));
            }

            // 向pre添加<SubMenu>
            pre.push(
              <SubMenu
                key={item.path}
                title={
                  <span>
                    {item.icon ? <Icon type={item.icon} /> : null}
                    <span>{item.title}</span>
                  </span>
                }
              >
                {this.getMenuNodes(item.children)}
              </SubMenu>
            );
          }

        }
      }

      return pre;
    }, []);
  };
  getItemMenu(paths,list=menuList,val){
    let str=''
    // eslint-disable-next-line array-callback-return
    for (let i=0; i<list.length;i++){
      const {activeMenu=null,path=null,children=null} = list[i]
      if(activeMenu && path === paths){
        str=activeMenu
        return str
      }else if(path === paths){
        str=path
        return str
      }else if(children && children.length>0){
        if(this.getItemMenu(paths,children,1)){
          return this.getItemMenu(paths,children,1) || str
        }
      }
    }
    return str
  }
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const _items = reorder(
      this.state.menuTreeNode,
      result.source.index,
      result.destination.index
    );
    this.setState({
      menuTreeNode: _items,
    });
  };

  handleMenuSelect = ({ key = "/dashboard" }) => {
    const navActive=this.props.navActive;
    let menuItem = getMenuItemInMenuListByProperty(this.switchList(navActive), "path", key);
    if(key!=='/personalCenter'){
    this.props.addTag(menuItem);
    }
  };
  switchList=(navActive)=>{

    switch (navActive) {
      case '1':
        return menuList;
        case '2':
          return menuListTwo;
          case '3':
            return menuConfigThree;

      default:
        return menuListTwo;
    }
  }
  componentWillMount() {
    const navActive=this.props.navActive;
    // const menuTreeNode = this.getMenuNodes(menuList);
    const menuTreeNode = this.getMenuNodes(this.switchList(navActive));
    this.setState({
      menuTreeNode,
    });
    const userInfo=JSON.parse(localStorage.getItem("userInfo")) || {}
    this.setState({
      keys:userInfo.key,
      userInfo
    });
    const path = this.props.location.pathname;
    // this.handleMenuSelect({key:path});
  }
  componentDidUpdate(prevProps) {
    setTimeout(() => {
      const navActive=this.props.navActive;
    if (this.props.location !== prevProps.location && navActive!=prevProps.navActive) {
      const menuTreeNode = this.getMenuNodes(this.switchList(navActive));
      this.setState({
        menuTreeNode,
      });
      // 在这里执行你想要的操作
    }
    },0)

  }
  render() {
    const navActive=this.props.navActive;
    const path = this.props.location.pathname;
    const activePath = this.getItemMenu(path,this.switchList(navActive))
    const openKey = this.state.openKey;
    return (
      <div className="sidebar-menu-container">
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.menuTreeNode.map((item, index) => (
                    // ref={provided.innerRef}
                    <div
                    key={index}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Menu
                      mode="inline"
                      theme="light"
                      
                      // onSelect={this.handleMenuSelect}
                      selectedKeys={[activePath]}
                      defaultOpenKeys={openKey}
                    >
                      {item}
                    </Menu>
                  </div>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    ...state.user,
    ...state.settings,
  };
};
export default connect(mapStateToProps, { addTag })(withRouter(Meun));
