import request from '@/utils/request'

export function reqLogin(data) {
  return request({
    url: '/web/user/sys/login',
    method: 'post',
    data
  })
}

export function reqLogout() {
  return request({
    url: '/web/user/sys/logout',
    method: 'post',
  })
}

export function reqFindPassword(data) {
  return request({
    url: '/web/user/sys/findPassword',
    method: 'post',
    data
  })
}