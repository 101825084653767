import * as types from "../action-types";
import { reqUserInfo } from "@/api/user";
import { sendMsg } from "@/api/common";
import {dealKey} from '@/config/dealKey'
export const getUserInfo = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    reqUserInfo()
      .then((response) => {
        const { data } = response;
        if (data.code === 200) {
          const userInfo = data.data;
          userInfo.key=[...userInfo.key]
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          dispatch(setUserInfo(userInfo));
          resolve(data);
        } else {
          const msg = data.msg;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const grtSendMsg = (mobile,type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    sendMsg({mobile,type})
      .then((response) => {
        const { data } = response;
        if (data.code === 200) {
          resolve(data);
        } else {
          const msg = data.msg;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setUserToken = (token) => {
  return {
    type: types.USER_SET_USER_TOKEN,
    token,
  };
};

export const setUserInfo = (userInfo) => {
  return {
    type: types.USER_SET_USER_INFO,
    ...userInfo,
  };
};

export const resetUser = () => {
  return {
    type: types.USER_RESET_USER,
  };
};
